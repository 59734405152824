/**
 * Logo component.
 * @module components/theme/Logo/Logo
 */
import { useEffect, useState } from 'react';
import { Image } from 'semantic-ui-react';
import { ConditionalLink } from '@plone/volto/components';
import LogoImage from '@plone/volto/components/theme/Logo/Logo.svg';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getNavroot } from '@plone/volto/actions';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import {
  flattenToAppURL,
  hasApiExpander,
  getBaseUrl,
} from '@plone/volto/helpers';

// Logo blanc
import logoEcityWhite from './img/ecityclic_espublico-w.svg';

// i18n
const messages = defineMessages({
  strHome: {
    id: 'Home',
    defaultMessage: 'Home',
  }
});


/**
 * Logo component class.
 * @function Logo
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component.
 */
const Logo = ({ intl }) => {
  const pathname = useLocation().pathname;
  const site = useSelector((state) => state.site.data);
  const navroot = useSelector((state) => state.navroot.data);
  const dispatch = useDispatch();

  // Mirem si highContrast està al body per canviar el logo
  const [hasHighContrast, setHasHighContrast] = useState(false);

  useEffect(() => {
    if (pathname && !hasApiExpander('navroot', getBaseUrl(pathname))) {
      dispatch(getNavroot(getBaseUrl(pathname)));
    }

        // Function to check if body has the class "highContrast"
        const checkHighContrastClass = () => {
          const bodyHasHighContrast = document.body.classList.contains('highContrast');
          setHasHighContrast(bodyHasHighContrast);
        };
    
        // Check on initial mount
        checkHighContrastClass();
    
        // Create a MutationObserver to listen for changes to the body element
        const observer = new MutationObserver(() => {
          checkHighContrastClass();
        });
    
        // Start observing the <body> element for changes to the class attribute
        observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });
    
        // Cleanup observer on unmount
        return () => observer.disconnect()
    
  }, [dispatch, pathname]);

  const navRootPath = flattenToAppURL(navroot?.navroot?.['@id']) || '/';
  const currentURLIsNavRoot = pathname !== navRootPath;

  return (
    <ConditionalLink
      href={navRootPath} 
      className="portalLogo__link"
      // In case that the content returns 404, there is no information about the portal
      // then render the link anyways to get out of the Unauthorized page
      condition={!navroot || currentURLIsNavRoot}
      title={intl.formatMessage(messages.strHome) + '.'}
    >
      <span className='portalLogo__wrapper d-block'>
      {hasHighContrast ? (
        <Image  
          className="portalLogo"
          src={logoEcityWhite}
          alt="ecityclic espúblico."
          width="1787" height="502"
        />        
      ) : (        
        <Image  
          className="portalLogo"
          src={
            site['plone.site_logo']
              ? flattenToAppURL(site['plone.site_logo'])
              : LogoImage
          }
          alt="ecityclic espúblico."
          width="1787" height="502"
        />
      )}
      </span>
    </ConditionalLink>
  );
};

export default injectIntl(Logo);
