/**
 * Footer component.
 * @module components/Footer/Footer
 */

import React from 'react';
import { useEffect, useState } from 'react';
import { Form, Button, Container, Grid, Header, Segment, Image } from 'semantic-ui-react';
import { faFacebookF, faXTwitter, faYoutube, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { map } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { UniversalLink, Logo, Icon } from '@plone/volto/components';
import { flattenToAppURL, addAppURL } from '@plone/volto/helpers';
import LogoENI from './img/esquema-nacional-interoperabilidad.svg';
import LogoENS from './img/esquema-nacional-seguridad.png';
import LogoISO9001 from './img/iso-9001.svg';
import LogoISO14001 from './img/iso-14001.svg';
import LogoENAC27001 from './img/enac-27001.svg';
import LogoHappy from './img/happy-at-work.png'; 
import LogoECityClic from './img/ecityclic_espublico.svg'; 
import LogoPYME from './img/pyme-innovadora.svg';
import bigBanner01  from '../../icons/ilustracion-01.svg';
import bigBanner02  from '../../icons/ilustracion-02.svg';
import bigBanner03  from '../../icons/ilustracion-03.svg';
import bigBanner04  from '../../icons/ilustracion-04.svg';

import { useGoogleAnalytics } from '../../helpers/useGoogleAnalytics';

import {
  usePanelConfigAndPreferences,
  GDPRCookies,
  getLocaleConf, 
} from 'volto-gdpr-privacy/helpers';


const messages = defineMessages({
  footerNav: {
    id: 'Footer navigation',
    defaultMessage: 'Footer navigation',
  },
  writeEmail: {
    id: 'Write here your email',
    defaultMessage: 'Write here your email',
  },
	labelEmail: {
    id: 'My email',
    defaultMessage: 'My email',
  } 
});

const socialIcons = {
  facebook: faFacebookF,
  twitter: faXTwitter,
  youtube: faYoutube,
  instagram: faInstagram,
  linkedin: faLinkedin
}

const bigBanner = [bigBanner01, bigBanner02, bigBanner03, bigBanner04];

const Footer = ({ intl }) => {
  const { siteActions = [] } = useSelector(
    (state) => ({
      siteActions: state.actions?.actions?.site_actions,
    }),
    shallowEqual,
  );
  const { socialActions = [] } = useSelector(
    (state) => ({
      socialActions: state.actions?.actions?.social_actions,
    }),
    shallowEqual,
  );
  const { solutionActions = [] } = useSelector(
    (state) => ({
      solutionActions: state.actions?.actions?.solution_actions,
    }),
    shallowEqual,
  );
  const { usActions = [] } = useSelector(
    (state) => ({
      usActions: state.actions?.actions?.us_actions,
    }),
    shallowEqual,
  );

  const content = useSelector((state) => state?.content?.data);
  const year = new Date().getFullYear();
  const newsletterURL = 'https://ecityclic.us14.list-manage.com/subscribe'
  const getNewsletterInfo = function (content) {
    if (content?.language?.token === 'ca')
    	return {newsletterU: 'a8be566973d1ad77c05b8f410', newsletterID: '935d5b53f8'}
    return {newsletterU: 'a8be566973d1ad77c05b8f410', newsletterID: '00105e1b51'}
  }
  const {newsletterU, newsletterID } = getNewsletterInfo(content)
  const getBannerURL = function (content) {
    if (content?.language?.token === 'ca')
      return '/ca/les-iniciatives-de-sostenibilitat-a-ecityclic'
    return '/es/las-iniciativas-de-sostenibilidad-en-ecityclic'
  }
  const bannerURL = getBannerURL(content)

	const getHappyURL = function (content) {
		if (content?.language?.token === 'ca')
			return '/ca/actualitat/ecityclic-obte-la-certificacio-happyindex-atwork-2024'
		return '/es/actualidad/ecityclic-obtiene-la-certificacion-happyindex-atwork-2024'
	}
	const happyURL = getHappyURL(content)

 	const cookies = new GDPRCookies();
 	const { defaultPreferences } = usePanelConfigAndPreferences(cookies);
  const gdprPreferences = useSelector(
    (state) => state.gdprPrivacyConsent.preferences ?? defaultPreferences,
  );

	useGoogleAnalytics(gdprPreferences?.GANALYTICS)

	// Randomly select banner image
  const [numImage, setRandom] = useState(Math.floor(Math.random() * 4));
	useEffect(() => { 
    return () => {setRandom(Math.floor(Math.random() * 4));};
  }, [content]);

	// Back to top button
	const [showsScrolBtn, setShowScrolBtn] = useState(true);

	useEffect(() => {
		const handleButtonVisibility = () => {
			window.scrollY > 100 ? setShowScrolBtn(true) : setShowScrolBtn(false);
		};

		window.addEventListener("scroll", handleButtonVisibility);
		return () => {
			window.addEventListener("scroll", handleButtonVisibility);
		};
	}, []);

  return (
    <footer>

		{/* BIG BANNER */}
		<Segment as="section" className="bigBanner" basic>
			<Container>
				<div className="bigBanner__wrapper">
					<Grid >
						<Grid.Row columns={12}  >
							<Grid.Column mobile={12} tablet={8} computer={5} largeScreen={7} widescreen={5} className="ms-xl-auto">
								<Header as="h2" className="bigBanner__title"><FormattedMessage id="Con ecityclic, menos papel y más eficiencia" defaultMessage="Con ecityclic, menos papel y más eficiencia"  /></Header>
								<p className='bigBanner__desc'><FormattedMessage id="Transforma tu entidad con nosotros. Únete a la revolución ecityclic y haz que tu Administración Pública sea parte de un futuro más verde." defaultMessage="Transforma tu entidad con nosotros. Únete a la revolución ecityclic y haz que tu Administración Pública sea parte de un futuro más verde."  /></p>

                <UniversalLink href={flattenToAppURL(bannerURL)} className="ui button primary">
                  <FormattedMessage id="Read more" defaultMessage="Read more"  /><span className="visually-hidden"><FormattedMessage id="Con ecityclic, menos papel y más eficiencia" defaultMessage="Con ecityclic, menos papel y más eficiencia"  /></span>
                </UniversalLink>

							</Grid.Column>
							<Grid.Column mobile={12} tablet={4} computer={5} largeScreen={5} widescreen={5} className="mx-xl-auto" aria-hidden="true">								
								<Icon name={bigBanner[numImage]} className="bigBanner__img"  />
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>
			</Container>
		</Segment>

		{/* FOOTER */} 
	  <Segment id="footer" className="portalFooter" basic>
        <Container >
					{/* NEWSLETTER */}
          <Grid as="section" id="newsletter" className="portalFooter__newsletter">
            <Grid.Row columns={12}>
            	<Grid.Column mobile={12} tablet={5} computer={4} largeScreen={4} className="portalFooter__newsletter__intro">
                <Header size='small' as="h2">
                  <FormattedMessage id="Join our digital community!" defaultMessage="Join our digital community!"  />
                </Header>
								<p>
                  <FormattedMessage id="Subscribe to our newsletter and get the latest news about innovative digital solutions." defaultMessage="Subscribe to our newsletter and get the latest news about innovative digital solutions."  />								
								</p>
              </Grid.Column>
							<Grid.Column mobile={12} tablet={7}  computer={8} largeScreen={7} floated='right'>
								<form className="ui form portalFooter__newsletter__form" method="get" action={newsletterURL}>
									<Form.Group>
										<Form.Input id="newsletter-input" name="MERGE0" required className="portalFooter__newsletter__form__input" fluid label={intl.formatMessage(messages.labelEmail)} placeholder={intl.formatMessage(messages.writeEmail)} type='email'  />
										<Form.Button type="sumit" className="mt-3 mt-sm-0"><FormattedMessage id="Subscribe" defaultMessage="Subscribe"  /></Form.Button>
									</Form.Group>
									<input type="hidden" name="u" value={newsletterU} />
                  <input type="hidden" name="id" value={newsletterID} />
								</form>
							</Grid.Column>
						</Grid.Row>
          </Grid>

					{/* CERTIFICATS */}
					<Segment as="section" basic className="portalFooter__seals">
						<h2 className="visually-hidden"><FormattedMessage id="Certificates" defaultMessage="Certificates"  /></h2>
						<Image src={LogoPYME} alt="PYME Innovadora 2027" width="149" height="213" className="opacity-75" />
						<a className='portalFooter__seals__link' href='https://www.ecityclic.com/ca/recursos/certificats/iso-9001-es.pdf/@@download/file'><Image src={LogoISO9001} alt="ICDQ ISO 9001 Certified Compnay." width="61" height="28" /></a>
						<a className='portalFooter__seals__link' href='https://www.ecityclic.com/ca/recursos/certificats/iso-14001-es.pdf/@@download/file'><Image src={LogoISO14001} alt="ICDQ ISO 14001 Certified Company" width="61" height="28" /></a>
						<a className='portalFooter__seals__link' href='https://www.ecityclic.com/ca/recursos/certificats/ens-alto.pdf/@@download/file'><Image src={LogoENS} alt="Esquema Nacional de Seguridad."  width="111" height="135" /></a>
						<a className='portalFooter__seals__link' href='https://www.ecityclic.com/ca/recursos/certificats/iso-27001-esp.pdf/@@download/file'><Image src={LogoENAC27001} alt="OCA ISO/IEC 27001 Seguridad de la información." width="36" height="39"  /></a>
						<Image src={LogoENI} alt="Esquema Nacional de Interoperatibilidad." width="63" height="30" />
						<a className="portalFooter__seals__link" href={flattenToAppURL(happyURL)}><Image src={LogoHappy} alt="Certified HappyIndex at work España 2024" width="200" height="245" className="opacity-75" /></a>
					</Segment>

					<Grid>
						<Grid.Row columns={12}>
							<Grid.Column id="portalInfo" mobile={12} tablet={5} computer={4} largeScreen={4} widescreen={4}>
								<Image className="portalLogo__img" src={LogoECityClic} alt="ecityclic espúblico." width="1787" height="502" />
								<p className='mb-5'> <a href="tel:+34973931001"><strong className='visually-hidden'>telèfon: </strong>(+34) 973 931 001</a><br />
								<a href="mailto:hola@ecityclic.com"><strong className='visually-hidden'>email:</strong>	hola@ecityclic.com</a></p>
							</Grid.Column>
							<Grid.Column mobile={12} tablet={7} computer={8} largeScreen={7} widescreen={7} floated='right' as="nav" aria-label={intl.formatMessage(messages.footerNav)}>
								<ul className="portalFooter__nav list-unstyled">
									<li>
										<Header as="p" className="portalFooter__nav__title"><FormattedMessage id="Solutions" defaultMessage="Solutions"  /></Header>
										<ul className="portalFooter__nav__list list-unstyled">

											{solutionActions?.length
											? map(solutionActions, (item) => (
												<li key={item.id}>
													<UniversalLink  href={item.url ? flattenToAppURL(item.url) : addAppURL(item.id)}>
														{item?.title}
													</UniversalLink>
													</li>
											))
										: null}

										</ul>	
									</li>
									<li>
										<Header as="p" className="portalFooter__nav__title"><FormattedMessage id="Us" defaultMessage="Us" /></Header>
										<ul className="portalFooter__nav__list list-unstyled">
											
											{usActions?.length
											? map(usActions, (item) => (
												<li key={item.id}>
													<UniversalLink href={item.url ? flattenToAppURL(item.url) : addAppURL(item.id)}>
														{item?.title}
													</UniversalLink>
												</li>
											))
										: null}
										</ul>	
									</li>
									<li>
										<Header as="p" className="portalFooter__nav__title"><FormattedMessage id="Legal terms" defaultMessage="Legal terms"  /></Header>
										<ul className="portalFooter__nav__list list-unstyled">

										{siteActions?.length
											? map(siteActions, (item) => (
												<li key={item.id}>
													<UniversalLink  href={item.url ? flattenToAppURL(item.url) : addAppURL(item.id)}>
														{item?.title}
													</UniversalLink>
												</li>
											))
										: null}

										</ul>
									</li>
									<li className='portalFooter__nav__xxss'>
										<Header as="p" className="portalFooter__nav__title mb-3"><FormattedMessage id="Follow us" defaultMessage="Follow us"  /></Header>
										<ul className='list-unstyled d-flex'>
											{socialActions?.length
												? map(socialActions, (item, index) => (
													<li>
														<a key={item.id} target="_blank" rel="noopener" href={item.url}>
															<FontAwesomeIcon icon={socialIcons[item.id]} />
															<span className="visually-hidden">{item?.title} <FormattedMessage id="Opens in new window" defaultMessage="Opens in new window"  /></span>															
														</a>
													</li>
												))
											: null}
										</ul>
									</li>
								</ul>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<Button type="button" className={"backToTop" + (showsScrolBtn ? ' backToTop--show ' : ' backToTop--hide ')} onClick={() => { window.scrollTo({ top: 0, behavior: "smooth"}); }}>
						<FontAwesomeIcon icon={faArrowUp} />
						<span className="visually-hidden"><FormattedMessage id="Back tot top" defaultMessage="Back tot top"  /></span>
					</Button>
					<p className="mb-0 pt-3 fs-sm opacity-75">&copy;{(new Date().getFullYear())} ecityclic</p>

        </Container>
	  </Segment>

    </footer>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
